import urljoin from 'url-join';
import get from 'lodash/get';
import { ADMIN_SVC_URL, AEM_BASE_URL } from './env';
import { getSelectedLocale } from '../helpers/locale';

const ADMIN_SVC_BASE_URL = urljoin(ADMIN_SVC_URL, 'v1');

export const TRANSLATIONS_ENDPOINT = urljoin(ADMIN_SVC_BASE_URL, 'translations');
export const AEM_API = AEM_BASE_URL;

export const WORKSHOP = '/workshop';
export const WORKSHOP_TR = '/servis';
export const CONSUMER = '/consumer';
export const CONSUMER_TR = '/aracsahibi';
export const COOKIES = '/cookies';
export const COOKIES_TR = '/cerez-politikasi';
export const CONTACT_US = '/contact-us';
export const CONTACT_US_TR = '/iletisim';
export const FAQ = '/faq';
export const FAQ_TR = '/sss';

export const ROUTE_MAP = {
  en: {
    workshop: WORKSHOP,
    consumer: CONSUMER,
    cookies: COOKIES,
    contactUs: CONTACT_US,
    faq: FAQ,
  },
  tr: {
    workshop: WORKSHOP_TR,
    consumer: CONSUMER_TR,
    cookies: COOKIES_TR,
    contactUs: CONTACT_US_TR,
    faq: FAQ_TR,
  },
};

export const getLocalizedRoutes = (key: string): string => {
  const routes: Array<string> = [];
  Object.entries(ROUTE_MAP).forEach(([, localeRoutes]) => {
    const link = localeRoutes[key];
    if (link) {
      routes.push(link.replace('/', ''));
    }
  });
  return `/(${routes.join('|')})/`;
};

export const getLocalisedRoute = (key: string): string => {
  const locale = getSelectedLocale();
  return get(ROUTE_MAP, `${locale}.${key}`, '/');
};

export const getIsCurrentRouteByKey = (key: string): boolean => {
  const path = window.location.pathname.split('/').pop();
  return getLocalizedRoutes(key).includes(path);
};
