/* eslint-disable */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Logo from 'assets/images/logo.svg';
import styled from 'styled-components';
import { Colors } from '@smp/carama-ui-components';
import { Hidden } from '@material-ui/core';
import { getLocalisedRoute } from 'constants/urls';

const { GREEN } = Colors;

const HeaderWrapper = styled(Grid)`
  background: ${GREEN};
  padding: 25px 30px;

  @media only screen and (max-width: 600px) {
    padding: 25px 15px;
  }

  img {
    @media only screen and (max-width: 600px) {
      width: 195px;
    }
  }
`;

const HeaderLinks = styled(Grid)`
  color: #fff;

  > * {
    padding: 0px 10px;

    @media only screen and (max-width: 600px) {
      padding-right: 0px;
    }
  }

  .MuiGrid-root {
    display: flex;
    align-items: center;
  }

  h4 {
    margin: 0px;
  }

  a {
    color: #fff;
  }
`;

const VerticalBorder = styled(Grid)`
  border-left: 1px solid #fff;
  margin-left: 20px;
`;

type SiteHeaderProps = {
  consumerText: string;
  workshopText: string;
};

const SiteHeader: React.FC<SiteHeaderProps> = ({ consumerText, workshopText }: SiteHeaderProps) => {
  // const appUrl = window.location.href;
  const navigateTo = path => {
    console.log(window.location.origin + path);
    window.location.assign(window.location.origin + path);
  };

  return (
    <HeaderWrapper container alignItems="center" justify="space-between">
      <Grid item>
        <Grid container alignItems="center">
          <img src={Logo} alt="CARAMA" title="CARAMA" />
        </Grid>
      </Grid>
      <Grid item>
        <HeaderLinks container>
          <Hidden smDown>
            <Grid item>
              <h4>
                <a href="" onClick={() => navigateTo(`${getLocalisedRoute('consumer')}`)}>
                  {consumerText}
                </a>
              </h4>
            </Grid>
            <VerticalBorder />
            <Grid item>
              <h4>
                <a href="" onClick={() => navigateTo(`${getLocalisedRoute('workshop')}`)}>
                  {workshopText}
                </a>
              </h4>
            </Grid>
          </Hidden>
        </HeaderLinks>
      </Grid>
    </HeaderWrapper>
  );
};

export default SiteHeader;
