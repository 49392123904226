import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { COOKIE_POLICY_CONSTANTS } from 'constants/general';
import { getLocalisedRoute } from 'constants/urls';
import { StyledCookieBanner } from './cookie-banner-styles';
import { Button } from '@smp/carama-ui-components';
import { TranslatedText } from '../Translators';
import { BannerContainer } from 'styles/root-styles';
import {
  getLocalStorageCookiePolicyByKey,
  setLocalStorageCookiePolicyByKey,
  addBodyClass,
  removeBodyClass,
} from '../../helpers/cookies';

const CookieBanner: React.FC = () => {
  const { policyAccepted, performanceCookies, marketingCookies } = COOKIE_POLICY_CONSTANTS;
  const isPolicyAccepted = getLocalStorageCookiePolicyByKey(policyAccepted);
  const [cookiePolicyAccepted, setCookiePolicy] = useState(false);

  const [cookieStickyBanner, setCookieStickyBanner] = useState(false);

  const appUrl = window.location.href;

  const cookiesLink = `${appUrl.replace(/\/$/, '')}${getLocalisedRoute(
    'consumer'
  )}${getLocalisedRoute('cookies')}`;

  const handlePageDisable = (): void => {
    const isCookieRoot = window.location.href.includes(getLocalisedRoute('cookies'));

    if (cookiePolicyAccepted) {
      removeBodyClass(['cookie-page-disable', 'enable-cookie-preferences']);
    } else if (isCookieRoot) {
      addBodyClass('enable-cookie-preferences');
      removeBodyClass('cookie-page-disable');
    } else {
      addBodyClass('cookie-page-disable');
      removeBodyClass('enable-cookie-preferences');
    }
  };

  const setCookiePolicies = (): void => {
    if (!cookiePolicyAccepted) {
      return;
    }

    setLocalStorageCookiePolicyByKey(policyAccepted, true);
    setLocalStorageCookiePolicyByKey(performanceCookies, true);
    setLocalStorageCookiePolicyByKey(marketingCookies, true);
  };

  const handleCookieBannerScroll = (): void => {
    document.body.addEventListener('scroll', () => {
      if (document.body.scrollTop > 100) {
        setCookieStickyBanner(true);
      } else {
        setCookieStickyBanner(false);
      }
    });
  };

  useEffect(() => {
    if (!cookiePolicyAccepted) {
      handleCookieBannerScroll();
    }
  }, []);

  useEffect(() => {
    if (!isPolicyAccepted) {
      handlePageDisable();
      setCookiePolicies();
    }
  });

  return (
    <>
      {!cookiePolicyAccepted && !isPolicyAccepted && (
        <BannerContainer
          className={`${
            cookieStickyBanner ? 'cookie-banner cookie-banner-fixed' : 'cookie-banner'
          }`}>
          <StyledCookieBanner>
            <Grid container justify={'space-between'}>
              <Grid item xs={12}>
                <h2>
                  <TranslatedText id="cookie-banner-header" />
                </h2>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={8}>
                    <p>
                      <TranslatedText id="cookie-banner-description" />
                    </p>
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <Grid container direction={'column'} spacing={3}>
                      <Grid data-qa="manage-preferences" item>
                        <a href={cookiesLink} className="manageCookiesLink">
                          <TranslatedText id="cookie-banner-manage-cookies-link" />
                        </a>
                      </Grid>
                      <Grid item>
                        <Button
                          id="btn-cookies-agree"
                          text={<TranslatedText id="btn-cookies-agree" />}
                          onClick={() => setCookiePolicy(true)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </StyledCookieBanner>
        </BannerContainer>
      )}
    </>
  );
};

export default CookieBanner;
