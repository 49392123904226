import { applyMiddleware, createStore, compose, combineReducers, Store } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import reducers from '../../src/store/reducers';
import history from '../helpers/history';
import rootSaga from './sagas';
import { AppState } from 'store/AppState';

export default function configureStore(): Store<AppState> {
  const sagaMiddleware = createSagaMiddleware();
  const routeMiddleware = routerMiddleware(history);
  const middlewares = [sagaMiddleware, routeMiddleware];
  const store = createStore(
    combineReducers({
      ...reducers,
      router: connectRouter(history),
    }),
    compose(applyMiddleware(...middlewares))
  );
  sagaMiddleware.run(rootSaga);

  return store;
}
