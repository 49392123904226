/* eslint-disable no-unused-expressions*/
import axios from 'axios/index';

import store from '../store/configureStore';
import { AppActions } from '../modules/app/actions';
import { DocumentNode } from 'graphql';
import { AxiosRequestConfig } from 'axios';

const get: any = async (url, config: AxiosRequestConfig = {}, showError = true) => {
  try {
    const response = await axios.get(url, config);
    return response.data;
  } catch (err) {
    if (showError) store.dispatch(AppActions.notifyApiError());
    throw err;
  }
};

const post: any = async (url, body) => {
  try {
    const response = await axios.post(url, body);
    return response.data;
  } catch (err) {
    store.dispatch(AppActions.notifyApiError());
    throw err;
  }
};

const put: any = async (url, body) => {
  try {
    const response = await axios.put(url, body);
    return response.data;
  } catch (err) {
    store.dispatch(AppActions.notifyApiError());
    throw err;
  }
};

const patch: any = async (url, body) => {
  try {
    const response = await axios.patch(url, body);
    return response.data;
  } catch (err) {
    store.dispatch(AppActions.notifyApiError());
    throw new Error(err);
  }
};

const del: any = async url => {
  try {
    const response = await axios.delete(url);
    return response.data;
  } catch (err) {
    store.dispatch(AppActions.notifyApiError());
    throw err;
  }
};

const getGqlString = (doc: DocumentNode): string => doc.loc && doc.loc.source.body;

const graphQl: any = async (
  url: string,
  query: DocumentNode,
  variables: {} = {},
  responseObjectPropName: string,
  headers = {}
) => {
  try {
    const response = await axios.post(
      url,
      { query: getGqlString(query), variables: variables },
      { headers }
    );

    return response.data.data[responseObjectPropName];
  } catch (err) {
    store.dispatch(AppActions.notifyApiError());
    throw err;
  }
};

const graphQlQuery: any = async (
  url: string,
  query: DocumentNode,
  variables: {} = {},
  responseObjectPropName: string,
  headers = {}
) => {
  try {
    const response = await axios.post(
      url,
      { query: getGqlString(query), variables: variables },
      { headers }
    );

    if (Object.prototype.hasOwnProperty.call(response.data, 'errors')) {
      return response.data;
    }

    return response.data.data[responseObjectPropName];
  } catch (err) {
    store.dispatch(AppActions.notifyApiError());
    throw err;
  }
};

export default { get, post, put, patch, del, graphQl, graphQlQuery };
