export const AppActionTypes = {
  RESET_STORE: 'RESET_STORE',
  API_EXCEPTION: 'API_EXCEPTION',
  IP_INFORMATION_SUCCESS: 'IP_INFORMATION_SUCCESS',
  CHANGE_LOCALE: 'CHANGE_LOCALE',
};

export const AppActions = {
  resetStore: () => ({
    type: AppActionTypes.RESET_STORE,
  }),
  notifyApiError: () => ({
    type: AppActionTypes.API_EXCEPTION,
  }),
  ipInformationFetchSuccess: ipData => ({ type: AppActionTypes.IP_INFORMATION_SUCCESS, ipData }),

  changeLocale: (locale: string, region: string) => ({
    type: AppActionTypes.CHANGE_LOCALE,
    payload: {
      locale,
      region,
    },
  }),
};
