import { all, put, fork, takeEvery, call } from 'redux-saga/effects';

import { SagaIterator } from 'redux-saga';
import { LoadingActions, LoadingActionTypes } from 'modules/loading/loading-actions';
import { apiFetchTranslations } from 'modules/loading/loading-api';
import {
  getSelectedLocale,
  getSelectedRegion,
  setDocumentLanguage,
  setLocalStorageLocale,
} from 'helpers/locale';
import countries from 'helpers/countries';
import { DEFAULT_COUNTRY_CODE } from 'constants/general';
import { AppActions, AppActionTypes } from '../app/actions';
import { ChangeLocaleAction } from '../app/types';

function* fetchTranslations(selectedLocale: string): SagaIterator {
  try {
    const translations = yield call(apiFetchTranslations, selectedLocale);

    yield put(LoadingActions.fetchTranslationsSuccess(translations));
  } catch (e) {
    yield put(LoadingActions.fetchTranslationsFailure(e));
  }
}

export function* watcherReferenceData(): SagaIterator {
  yield takeEvery(LoadingActionTypes.FETCH_REFERENCE_DATA, function*() {
    const isoCode = getSelectedRegion();

    const userLanguage = getSelectedLocale();

    try {
      let country = countries[isoCode] ? countries[isoCode] : countries[DEFAULT_COUNTRY_CODE];

      country = { ...country, selectedLocale: userLanguage };
      yield put(AppActions.ipInformationFetchSuccess(country));
      setLocalStorageLocale(userLanguage, isoCode);
      setDocumentLanguage(userLanguage, isoCode.toUpperCase());
      yield fork(fetchTranslations, country.selectedLocale);
    } catch (e) {
      console.error(e); // eslint-disable-line
    }
  });
}

function* watcherChangeLocale(): SagaIterator {
  yield takeEvery(AppActionTypes.CHANGE_LOCALE, function*(action: ChangeLocaleAction) {
    try {
      const { payload } = action;
      const { locale, region } = payload;
      setLocalStorageLocale(locale, region);

      window.location.reload();
      yield;
    } catch (e) {
      console.log(e); // eslint-disable-line
    }
  });
}

export default function* LoadingSaga(): SagaIterator {
  yield all([fork(watcherReferenceData), fork(watcherChangeLocale)]);
}
