import produce from 'immer';
import { FetchTranslationsSuccessAction } from './types/loading-action-types';
import { ReferenceDataState } from 'modules/loading/types/ReferenceDataState';
import { LoadingActionTypes } from 'modules/loading/loading-actions';

const initialState: ReferenceDataState = {
  isLoading: true,
  translations: [],
  translationsLoaded: false,
};

export default (state = initialState, action: FetchTranslationsSuccessAction): ReferenceDataState =>
  produce(state, draft => {
    switch (action.type) {
      case LoadingActionTypes.FETCH_TRANSLATIONS_SUCCESS: {
        const { translations } = action;
        draft.translations = translations;
        draft.translationsLoaded = true;
        return;
      }
      default:
        return state;
    }
  });
