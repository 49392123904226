import React from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { AppState } from 'store/AppState';
import { getTranslations } from '../../modules/loading/loading-selectors';

type Props = {
  children: React.ReactNode;
  translations: Record<string, string>;
};

const Body = ({ children, translations }: Props): React.ReactElement => (
  <IntlProvider locale={'en'} messages={translations}>
    <div className="page-wrapper">{children}</div>
  </IntlProvider>
);

const mapStateToProps = (state: AppState): Partial<Props> => ({
  translations: getTranslations(state),
});

export default connect(mapStateToProps)(Body);
