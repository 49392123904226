export const LOCALE_KEY = 'LOCALE';

export const DEFAULT_COUNTRY_CODE = 'TR';
export const DEFAULT_LANGUAGE = 'tr';

export const COOKIE_POLICY_KEY = 'COOKIE_POLICY';

export const COOKIE_POLICY_CONSTANTS = {
  policyAccepted: 'policyAccepted',
  performanceCookies: 'performanceCookies',
  marketingCookies: 'marketingCookies',
};

export const DEFAULT_COOKIE_POLICY = {
  [COOKIE_POLICY_CONSTANTS.policyAccepted]: false,
  [COOKIE_POLICY_CONSTANTS.performanceCookies]: false,
  [COOKIE_POLICY_CONSTANTS.marketingCookies]: false,
};
