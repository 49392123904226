import produce from 'immer';
import { CoreState, Actions } from './types';
import { AppActionTypes } from './actions';

const initialState: CoreState = { ipData: {} };

export default (state = initialState, action: Actions): CoreState =>
  produce(state, draft => {
    switch (action.type) {
      case AppActionTypes.IP_INFORMATION_SUCCESS: {
        draft.ipData = action.ipData;
        return;
      }
      case AppActionTypes.RESET_STORE: {
        state = initialState;
        return;
      }
      default:
        return state;
    }
  });
