// @ts-nocheck
import { createSelector } from 'reselect';

export const translationsLoadedSelector = (state: any): boolean =>
  state.referenceData.translationsLoaded;

export const getTranslations = (state: any): Record<string, string> => {
  if (!state.referenceData?.translations?.length) return {};

  return state.referenceData.translations.reduce((accumulator, translation) => {
    return { ...accumulator, [translation.key]: translation.value };
  }, {});
};

export const getIsLoading = createSelector(translationsLoadedSelector, translationsLoadComplete => {
  return !translationsLoadComplete;
});
