import { connectRouter, routerMiddleware } from 'connected-react-router';
import { applyMiddleware, combineReducers, compose, createStore, Store } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import { AppState } from 'store/AppState';
import history from 'helpers/history';
import rootSaga from './sagas';

export default function configureStore(): Store<AppState> {
  const sagaMiddleware = createSagaMiddleware();
  const routeMiddleware = routerMiddleware(history);
  const middlewares = [sagaMiddleware, routeMiddleware];
  const composeEnhancers =
    (process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

  const store = createStore(
    combineReducers({
      ...reducers,
      router: connectRouter(history),
    }),
    composeEnhancers(applyMiddleware(...middlewares))
  );

  sagaMiddleware.run(rootSaga);

  // expose store for our cypress tests, should never happen in production build
  window.__store__ = store;

  return store;
}
