// load raw env vars
export const APP_ID = process.env.CARAMA_PUBLIC_APP_ID || 'carama';
export const PUBLIC_URL = process.env.PUBLIC_URL || '';
export const RELEASE_NAME = process.env.CARAMA_PUBLIC_RELEASE_NAME || 'locally-run';
export const ENVIRONMENT_NAME = process.env.CARAMA_PUBLIC_ENVIRONMENT_NAME || 'local';
export const ADMIN_SVC_URL = process.env.CARAMA_PUBLIC_ADMIN_SVC_URL;
export const AEM_BASE_URL = process.env.CARAMA_PUBLIC_AEM_BASE_URL;
export const APPINSIGHTS_INSTRUMENTATION_KEY =
  process.env.CARAMA_PUBLIC_APPINSIGHTS_INSTRUMENTATION_KEY;
export const CARAMA_PUBLIC_USE_FB_PIXEL_TRACKING = process.env.CARAMA_PUBLIC_USE_FB_PIXEL_TRACKING;
export const AAD_TENANT_ID = process.env.CARAMA_PUBLIC_AAD_TENANT_ID;
export const AAD_CLIENT_ID = process.env.CARAMA_PUBLIC_AAD_CLIENT_ID;
export const USE_AD_AUTHENTICATION = process.env.CARAMA_PUBLIC_USE_AD_AUTHENTICATION;
export const AVAILABLE_COUNTRIES = process.env.CARAMA_PUBLIC_AVAILABLE_COUNTRIES || [];
export const AVAILABLE_LOCALES = process.env.CARAMA_PUBLIC_AVAILABLE_LOCALES || [];
