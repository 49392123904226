import React, { Component } from 'react';
import { Provider } from 'react-redux';
import store from './store/configureStore';
import history from './helpers/history';
import { theme } from 'styles/theme';
import { ConnectedRouter } from 'connected-react-router';
import { GlobalStyle } from 'styles/global-styles';
import { Route } from 'react-router';
import { ThemeProvider } from '@material-ui/core/styles';
import withAppInsights from './modules/app-insights';
import { PageContent, SiteContainer } from './styles/root-styles';
import Index from './components/Pages/Brochureware/Home';
import adAuthProvider from './modules/auth/ad-auth-provider';
import { AzureAD } from 'react-aad-msal';
import { USE_AD_AUTHENTICATION } from 'constants/env';
import AppContainer from './components/Pages/AppContainer';

class ConnectedApp extends Component {
  render(): React.ReactNode {
    return (
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <AppContainer>
              <SiteContainer>
                <GlobalStyle />
                <PageContent>
                  <Route path="/" component={Index} />
                </PageContent>
              </SiteContainer>
            </AppContainer>
          </ConnectedRouter>
        </Provider>
      </ThemeProvider>
    );
  }
}

class Root extends Component {
  render(): React.ReactNode {
    return USE_AD_AUTHENTICATION === 'true' ? (
      <AzureAD provider={adAuthProvider} forceLogin={true}>
        <ConnectedApp />
      </AzureAD>
    ) : (
      <ConnectedApp />
    );
  }
}

export default withAppInsights(Root);
