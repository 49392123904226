/* eslint-disable */
import React, { useState, useEffect } from 'react';
import SiteHeader from 'components/SiteHeader';
import Footer from 'components/Footer';
import Grid from '@material-ui/core/Grid';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import Button from '@material-ui/core/Button';
import Arrow from 'assets/images/Arrow.svg';
import { Hidden } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { LoadingActions } from 'modules/loading/loading-actions';
import { getSelectedLocale, getSelectedRegion } from 'helpers/locale';
import { getLocalisedRoute } from 'constants/urls';
import {
  LandingWrapper,
  LandingCards,
  LandingCard,
  LandingHeroImg,
  LandingInfo,
  MainContainer,
  LandingInfoCopy,
  LandingInfoText,
  ImgContainer,
  LineContainer,
  BenefitsSection,
  BenefitsSectionItem,
  BenefitsLink,
  ExploreSection,
  ExploreBtnContainer,
} from './styles';
import CookieBanner from 'components/CookieBanner/CookieBanner';

type Props = {
  // TODO: define type.
  content: Record<string, string>;
  resetBookingDate: Function;
};

const contentful = require('contentful');

// TODO: These will need to be env vars.
const contentfulClient = contentful.createClient({
  accessToken: 'lTSC3omD5KlsfP_yBx-AxolY-Tij8J2MlKS5_rpVkHM',
  space: 'g5djiaewcy21',
  environment: 'develop',
});

const Home: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const [pageData, setPageData] = useState<any>({});
  const pathname = window.location.pathname;
  const locale = getSelectedLocale();
  const region = getSelectedRegion();
  const appUrl = window.location.href;

  useEffect(() => {
    let tempLocale =
      locale === 'tr' ? 'tr-TR' : locale === 'en' ? 'en-US' : locale + '-' + region.toUpperCase();

    contentfulClient
      .getEntries({
        'fields.slug[match]': 'WorkshopLanding',
        content_type: 'page',
        include: 10,
        locale: tempLocale,
      })
      .then((entries: any) => {
        setPageData(entries?.items[0]?.fields?.content[0]?.fields || {});
      })

      .catch(() => setPageData({}));
  }, [pathname]);

  useEffect(() => {
    window.location.assign(window.location.origin + getLocalisedRoute('consumer'));
    dispatch(LoadingActions.fetchReferenceData());
  }, []);

  if (isEmpty(pageData)) return null;

  const {
    landingHeroCards,
    landingHeroImg,
    whatIsCaramaImage,
    whatIsCaramaText,
    forConsumersTitle,
    forConsumersInfo,
    forConsumerLink,
    forWorkshopTitle,
    forWorkshopInfo,
    forWorkshopLink,
    readyToExploreTitle,
    exploreConsumerButton,
    exploreWorkshopButton,
    footer,
    header,
  } = pageData;

  const extractText = data => {
    return data.reduce((acc, cur) => {
      const node = cur.nodeType;
      const hasNode = !!acc[node];
      const value = cur?.content[0]?.value;

      return {
        ...acc,
        [node]: hasNode ? [...(Array.isArray(acc[node]) ? acc[node] : [acc[node]]), value] : value,
      };
    }, {});
  };

  const getRichText = raw => {
    const data = get(raw, 'fields.content.content').filter(item => !!item?.content[0]?.value);
    return extractText(data);
  };

  const getImg = (data): Record<string, any> => {
    const { fields } = data;
    return {
      src: fields.file.url,
      alt: fields.description,
      title: fields.title,
    };
  };

  const getBenefitInfo = raw => {
    return raw.map(item => {
      const data = item.fields;

      return {
        img: getImg(data.image),
        heading: extractText(data.richText.content)['heading-3'],
        text: extractText(data.richText.content)['paragraph'],
      };
    });
  };

  const handleButtonClick = url => () => {
    window.location.href = url;
  };

  const getLnk = raw => {
    const target = raw.fields.content.content[0].content;
    const linkTarget = target.find(item => !isEmpty(item.data));

    return {
      url: linkTarget?.data?.uri,
      text: linkTarget?.content && linkTarget?.content[0]?.value,
      value: target && target[0]?.value,
    };
  };

  const consumerBenefits = getBenefitInfo(forConsumersInfo);
  const workshopBenefits = getBenefitInfo(forWorkshopInfo);
  const [
    workshopHeroCardText,
    workshopHeroCardButton,
    consumerHeroCardText,
    consumerHeroCardButton,
  ] = landingHeroCards;

  const footerSupport = extractText(footer.fields.support.content);
  const footercontact = extractText(footer.fields.contact.content);
  const regSelectorText = footer.fields.regionSelectorText;
  const footerLastRow = footer.fields.lastRow.map(item => {
    return getLnk(item);
  });

  const { consumerText, workshopText } = header.fields;

  const constructLink = path => {
    return `${appUrl.replace(/\/$/, '')}${path}`.replace('/web', '');
  };

  return (
    <LandingWrapper className="landing-wrapper">
      <SiteHeader {...{ consumerText, workshopText }} />
      <CookieBanner />
      <Grid container>
        <LandingHeroImg>
          <img
            src={getImg(landingHeroImg).src}
            alt={getImg(landingHeroImg).alt}
            title={getImg(landingHeroImg).title}
          />
        </LandingHeroImg>
      </Grid>
      <LandingCards container justify="center">
        <LandingCard item xs={11} sm={8} md={5} container>
          <Grid item xs={12}>
            <h5> {getRichText(consumerHeroCardText)['heading-5']} </h5>
          </Grid>
          <Grid item xs={12}>
            <h2> {getRichText(consumerHeroCardText)['heading-2']} </h2>
          </Grid>
          <Grid item xs={12}>
            <p> {getRichText(consumerHeroCardText)['paragraph']} </p>
          </Grid>
          <Grid item xs={12} container alignItems="flex-end">
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={handleButtonClick(constructLink(`${getLocalisedRoute('consumer')}`))}>
              {getRichText(consumerHeroCardButton)['paragraph']}
            </Button>
          </Grid>
        </LandingCard>
        <LandingCard item xs={11} sm={8} md={5} container>
          <Grid item xs={12}>
            <h5> {getRichText(workshopHeroCardText)['heading-5']} </h5>
          </Grid>
          <Grid item xs={12}>
            <h2> {getRichText(workshopHeroCardText)['heading-2']} </h2>
          </Grid>
          <Grid item xs={12}>
            <p> {getRichText(workshopHeroCardText)['paragraph']} </p>
          </Grid>
          <Grid item xs={12} container alignItems="flex-end">
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={handleButtonClick(constructLink(getLocalisedRoute('workshop')))}>
              {getRichText(workshopHeroCardButton)['paragraph']}
            </Button>
          </Grid>
        </LandingCard>
      </LandingCards>
      <MainContainer>
        <LandingInfo container>
          <LandingInfoCopy item container xs={12} lg>
            <LineContainer>
              <img src={Arrow} alt="arrow" />
            </LineContainer>
            <LandingInfoText item xs>
              <h2>{extractText(whatIsCaramaText.content)['heading-2']}</h2>
              <p>{extractText(whatIsCaramaText.content)['paragraph']}</p>
            </LandingInfoText>
          </LandingInfoCopy>
          <Hidden mdDown>
            <ImgContainer item xs={12} lg>
              <img
                src={getImg(whatIsCaramaImage).src}
                alt={getImg(whatIsCaramaImage).alt}
                title={getImg(whatIsCaramaImage).title}
              />
            </ImgContainer>
          </Hidden>
        </LandingInfo>
        <BenefitsSection>
          <h2> {extractText(forConsumersTitle.content)['heading-2']} </h2>
          <Grid container alignItems="flex-start">
            {workshopBenefits.map(benefitItem => {
              return (
                <BenefitsSectionItem item xs={12} md={4} container>
                  <Grid item xs={'auto'} md={12}>
                    <img
                      src={benefitItem.img.src}
                      alt={benefitItem.img.alt}
                      title={benefitItem.img.title}
                    />
                  </Grid>

                  <Grid item xs md={12}>
                    <Grid item xs={12}>
                      <h3>{benefitItem.heading}</h3>
                    </Grid>
                    <Grid item xs={12}>
                      <p>{benefitItem.text}</p>
                    </Grid>
                  </Grid>
                </BenefitsSectionItem>
              );
            })}
          </Grid>
          <BenefitsLink href={constructLink(`${getLocalisedRoute('workshop')}`)}>
            {forConsumerLink}
          </BenefitsLink>
        </BenefitsSection>
        <BenefitsSection>
          <h2> {extractText(forWorkshopTitle.content)['heading-2']} </h2>
          <Grid container alignItems="flex-start">
            {consumerBenefits.map(benefitItem => {
              return (
                <BenefitsSectionItem item xs={12} md={4} container>
                  <Grid item xs={'auto'} md={12}>
                    <img
                      src={benefitItem.img.src}
                      alt={benefitItem.img.alt}
                      title={benefitItem.img.title}
                    />
                  </Grid>
                  <Grid item xs md={12}>
                    <Grid item xs={12}>
                      <h3>{benefitItem.heading}</h3>
                    </Grid>
                    <Grid item xs={12}>
                      <p>{benefitItem.text}</p>
                    </Grid>
                  </Grid>
                </BenefitsSectionItem>
              );
            })}
          </Grid>
          <BenefitsLink href={constructLink(getLocalisedRoute('consumer'))}>
            {forWorkshopLink}
          </BenefitsLink>
        </BenefitsSection>
        <ExploreSection>
          <Grid container justify="center" alignItems="center">
            <h2> {extractText(readyToExploreTitle.content)['heading-2']} </h2>
          </Grid>

          <Grid container justify="center" alignItems="center">
            <ExploreBtnContainer item xs={6} md={4} lg={'auto'}>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                onClick={handleButtonClick(constructLink(`${getLocalisedRoute('consumer')}`))}>
                {extractText(exploreConsumerButton.content)['paragraph']}
              </Button>
            </ExploreBtnContainer>

            <ExploreBtnContainer item xs={6} md={4} lg={'auto'}>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                onClick={handleButtonClick(constructLink(getLocalisedRoute('workshop')))}>
                {extractText(exploreWorkshopButton.content)['paragraph']}
              </Button>
            </ExploreBtnContainer>
          </Grid>
        </ExploreSection>
      </MainContainer>
      <Footer
        {...{
          regSelectorText,
          footerSupport,
          footercontact,
          footerLastRow,
        }}
      />
    </LandingWrapper>
  );
};

export default Home;
