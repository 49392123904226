import { TRANSLATIONS_ENDPOINT } from 'constants/urls';

import { APP_ID, AVAILABLE_COUNTRIES, AVAILABLE_LOCALES } from 'constants/env';

interface AppConfigurations {
  appId: string;
  appName: string;
  isProduction: boolean;
  dateFormat: {
    longDate: string;
    shortDate: string;
    longDay: string;
    abbreviatedDate: string;
  };
  serviceEndpoints: Record<string, string>;
  availableCountries: string[];
  availableLocales: string[];
}

class Config {
  config: AppConfigurations;
  constructor() {
    this.config = {
      appId: APP_ID,
      appName: 'carama',
      isProduction: Boolean(process.env.NODE_ENV === 'production'),
      dateFormat: {
        longDate: 'EEEE, d MMMM yyyy',
        shortDate: 'D MMMM yyyy',
        longDay: 'dddd',
        abbreviatedDate: 'EEE d MMM',
      },
      serviceEndpoints: {
        fetchTranslationsApi: TRANSLATIONS_ENDPOINT,
      },
      availableCountries: AVAILABLE_COUNTRIES as string[],
      availableLocales: AVAILABLE_LOCALES as string[],
    };
  }

  get get(): AppConfigurations {
    Object.freeze(this.config);
    return this.config;
  }
}

export default new Config();
