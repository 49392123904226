export const LoadingActionTypes = {
  FETCH_REFERENCE_DATA: 'FETCH_REFERENCE_DATA',
  FETCH_REFERENCE_DATA_FAILURE: 'FETCH_REFERENCE_DATA_FAILURE',
  FETCH_TRANSLATIONS_SUCCESS: 'FETCH_TRANSLATIONS_SUCCESS',
  FETCH_TRANSLATIONS_ERROR: 'FETCH_TRANSLATIONS_ERROR',
};

export const LoadingActions = {
  fetchReferenceData: () => {
    return {
      type: LoadingActionTypes.FETCH_REFERENCE_DATA,
    };
  },
  fetchReferenceDataFailure: (error, key) => ({
    type: LoadingActionTypes.FETCH_REFERENCE_DATA_FAILURE,
    error,
    key,
  }),
  fetchTranslationsSuccess: translations => ({
    type: LoadingActionTypes.FETCH_TRANSLATIONS_SUCCESS,
    translations,
  }),
  fetchTranslationsFailure: error => ({
    type: LoadingActionTypes.FETCH_TRANSLATIONS_SUCCESS,
    error,
  }),
};
