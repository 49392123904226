import { createMuiTheme } from '@material-ui/core/styles';
import { Colors } from '@smp/carama-ui-components';
import { css } from 'styled-components';

const { GREEN, ERROR_RED_COLOR, LIGHT_GREEN_NEW, DARK_GREEN, ALMOST_BLACK } = Colors;

export const viewportSizes = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  custom: 320,
  phone: 480,
  tablet: 768,
  desktop: 992,
  large: 1200,
};

const muiTheme = createMuiTheme({
  breakpoints: {
    values: { ...viewportSizes },
  },
});

export const theme = createMuiTheme({
  overrides: {
    MuiTypography: {
      h1: {
        fontSize: '2.25rem',
        fontWeight: 700,
        lineHeight: '2.5rem',
        marginBottom: '0.75rem',
        color: ALMOST_BLACK,

        [muiTheme.breakpoints.down('sm')]: {
          fontSize: '2rem',
          marginBottom: '0.75rem',
        },
      },
      h2: {
        fontSize: '2rem',
        fontWeight: 700,
        lineHeight: '2rem',
        margin: '1rem 0',
        color: ALMOST_BLACK,

        [muiTheme.breakpoints.down('sm')]: {
          fontSize: '2rem',
        },
      },
      h3: {
        fontFamily: 'MuseoSans,serif',
        marginBottom: '1rem',
        lineHeight: '1.5rem',
        fontSize: '1.2rem',
      },
      h4: {
        fontSize: '16px',
        fontFamily: 'MuseoSans,serif',
        lineHeight: '24px',
        fontWeight: 500,
      },
      h5: {
        fontSize: '16px',
        fontFamily: 'MuseoSans,serif',
        lineHeight: '22px',
        fontWeight: 500,
      },
      body1: {
        fontSize: '1rem',
        fontFamily: 'MuseoSansLight,serif',
        marginBottom: '1rem',
        lineHeight: '1.5rem',
        '&.MuiTypography-colorError': {
          fontWeight: 500,
          fontFamily: 'MuseoSans,serif',
          color: ERROR_RED_COLOR,
        },
      },
      body2: {
        fontSize: '1rem',
        fontFamily: 'MuseoSans,serif',
        marginBottom: '1rem',
        lineHeight: '1.5rem',
        '&.MuiTypography-colorError': {
          fontFamily: 'MuseoSans,serif',
          color: ERROR_RED_COLOR,
        },
      },
      subtitle1: {
        fontFamily: 'MuseoSansLight,serif',
        marginBottom: '1rem',
      },
      subtitle2: {
        fontFamily: 'MuseoSansLight,serif',
        marginBottom: '1rem',
      },
    },
    MuiInput: {
      underline: {
        '&&&&:before': {
          borderBottomWidth: 2,
        },
      },
    },
    MuiMenuItem: {
      root: {
        '&:hover': {
          color: GREEN,
        },
      },
    },
    MuiStepIcon: {
      root: {
        '&$completed': {
          color: GREEN,
        },
      },
      active: {},
      completed: {},
    },
    MuiStepLabel: {
      label: {
        '&$active': {
          color: GREEN,
        },
      },
    },
    MuiStepConnector: {
      line: {
        borderColor: GREEN,
      },
    },
    MuiSvgIcon: {
      colorPrimary: {
        color: 'red',
      },
    },
    MuiDialogTitle: {
      root: {
        borderTop: `solid 9px ${GREEN}`,
        display: 'flex',
        fontFamily: 'MuseoSans',
        alignItems: 'flex-start',
        padding: '1em',
        '& h6': {
          display: 'flex',
          color: GREEN,
          fontFamily: 'MuseoSans',
          fontSize: '1.5em',
          justifyContent: 'flex-start',
          flex: 1,
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        marginBottom: 0,
      },
    },
  },
  palette: {
    primary: {
      main: GREEN,
      light: LIGHT_GREEN_NEW,
      dark: DARK_GREEN,
    },
    text: {
      primary: ALMOST_BLACK,
    },
  },
  typography: {
    fontFamily: 'MuseoSans,serif',
  },
});

export const media: Record<string, Function> = Object.keys(viewportSizes).reduce(
  (finalMedia, size) => {
    return {
      ...finalMedia,
      [size]: function(args) {
        //@ts-ignore
        return css`
          @media (max-width: ${viewportSizes[size]}px) {
            ${css`
              ${args.join()}
            `}
          }
        `;
      },
    };
  },
  {}
);
