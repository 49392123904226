import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import { Colors } from '@smp/carama-ui-components';

const { GREEN } = Colors;
export const LandingWrapper = styled.div`
  background: #f9f9f9;

  * {
    box-sizing: border-box;
  }

  h1 {
    font-family: GothamMid;
    color: #2e3037;
  }

  h2 {
    font-size: 36px;
    margin: 1em 0;
    font-family: GothamBold;
    color: #2e3037;

    @media only screen and (max-width: 600px) {
      font-size: 24px;
    }
  }

  h3 {
    font-family: GothamMid;
  }

  h4 {
  }

  h5 {
    font-size: 14px;
    color: #2e3037;
  }

  h6 {
  }

  p {
    font-size: 18px;
    color: #2e3037;
    font-family: GothamLight;

    @media only screen and (max-width: 600px) {
      font-size: 16px;
    }
  }

  button {
    padding: 10px;
  }
`;

export const LandingCards = styled(Grid)`
  position: relative;
  top: -220px;
  margin-bottom: -109px;

  @media only screen and (max-width: 520px) {
    && {
      top: -117px;
    }
  }
`;

export const LandingCard = styled(Grid)`
  background: #fff;
  border-radius: 23px;
  padding: 37px;
  box-shadow: #393939 0px 3px 29px -11px;

  && {
    margin: 0px 20px;
    margin-bottom: 51px;
    max-width: 451px;
  }

  h5 {
    color: ${GREEN};
    text-transform: uppercase;
    margin: 1em 0;
  }

  h2 {
    margin: 0;
  }

  p {
    margin: 2em 0;

    @media only screen and (max-width: 600px) {
      margin: 2em 0;
    }
  }
`;

export const LandingHeroImg = styled.div`
  max-height: 800px;
  overflow: hidden;

  img {
    width: 100vw;
  }
`;

export const LandingInfo = styled(Grid)`
  && {
    padding-bottom: 316px;
  }

  @media only screen and (max-width: 1279px) {
    && {
      padding-bottom: 80px;
    }
  }
  img {
    max-width: 550px;
  }
`;

export const MainContainer = styled.div`
  margin: auto;
  > * {
    padding: 40px;

    @media only screen and (min-width: 1679px) {
      padding: 40px 140px;
    }

    @media only screen and (max-width: 600px) {
      padding: 20px;
    }
  }
`;

export const LandingInfoCopy = styled(Grid)`
  overflow: hidden;
  h2 {
    margin-top: 0px;
  }

  p {
    font-size: 24px;
    line-height: 34px;

    @media only screen and (max-width: 600px) {
      font-size: 16px;
      line-height: 29px;
    }
  }
`;

export const LandingInfoText = styled(Grid)`
  padding-left: 38px;

  @media only screen and (max-width: 600px) {
    padding-left: 15px;
  }
`;

export const ImgContainer = styled(Grid)`
  text-align: right;
  position: relative;

  img {
    position: absolute;
    top: 0px;
    right: 0px;
  }
`;

export const LineContainer = styled(Grid)`
  position: relative;
  width: 51px;
  height: auto;

  img {
    position: absolute;
    top: 0px;
    left: 0px;
  }
`;

export const BenefitsSection = styled(Grid)`
  background: #fff;
  padding-bottom: 100px;

  h2 {
    margin-bottom: 0.5em;

    @media only screen and (max-width: 600px) {
      margin-bottom: 1.5em;
    }
  }
`;

export const BenefitsSectionItem = styled(Grid)`
  img {
    width: 150px;
    padding-right: 20px;
  }

  padding: 20px;
  align-items: flex-start;

  @media only screen and (max-width: 600px) {
    padding: 0px;

    && {
      margin: 20px 0px;
    }
  }

  h3 {
    font-size: 28px;

    @media only screen and (max-width: 800px) {
      margin-top: 0px;
      font-size: 19px;
    }
  }

  p {
    font-size: 16px;
  }
`;

export const BenefitsLink = styled.a`
  color: ${GREEN};
  font-size: 25px;

  text-decoration: underline;
  margin-top: 2em;
  display: inline-block;
  font-weight: 700;
  margin-top: 1em;

  @media only screen and (max-width: 800px) {
    font-size: 18px;
  }
`;

export const ExploreSection = styled(Grid)`
  padding: 60px;

  @media only screen and (max-width: 600px) {
    padding: 20px 20px 40px 20px;
  }

  h2 {
    margin-bottom: 1.5em;
  }

  button {
    margin: 0px 20px;
    @media only screen and (min-width: 1000px) {
      min-width: 300px;
    }
  }
`;

export const ExploreBtnContainer = styled(Grid)`
  display: flex;
`;
