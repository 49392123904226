import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import {
  APPINSIGHTS_INSTRUMENTATION_KEY,
  ENVIRONMENT_NAME,
  RELEASE_NAME,
} from '../../constants/env';
import history from '../../helpers/history';

const reactPlugin = new ReactPlugin();
const ai = new ApplicationInsights({
  config: {
    instrumentationKey: APPINSIGHTS_INSTRUMENTATION_KEY,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history },
    },
  },
  queue: [],
});

ai.loadAppInsights();
ai.addTelemetryInitializer(envelope => {
  envelope.tags['ai.cloud.role'] = 'carama-consumer-ui';
  envelope.data = {
    environment: ENVIRONMENT_NAME,
    release: RELEASE_NAME,
  };
});
ai.trackPageView();

export default (Component: any): any => withAITracking(reactPlugin, Component);
export const index = ai.appInsights;
