import { isNil } from 'lodash';
import { DEFAULT_COUNTRY_CODE, DEFAULT_LANGUAGE, LOCALE_KEY } from 'constants/general';
import { languages } from 'helpers/languages';

import countries from 'helpers/countries';

export const getSelectedLocale = (): string => {
  const localeJson = JSON.parse(localStorage.getItem(LOCALE_KEY));

  if (!isNil(localeJson) && !isNil(localeJson.locale) && !isNil(languages[localeJson.locale])) {
    return localeJson.locale;
  }

  return DEFAULT_LANGUAGE;
};

export const getSelectedRegion = (): string => {
  const localeJson = JSON.parse(localStorage.getItem(LOCALE_KEY));

  if (!isNil(localeJson) && !isNil(localeJson.region) && !isNil(countries[localeJson.region])) {
    return localeJson.region;
  }

  return DEFAULT_COUNTRY_CODE;
};

export const setLocalStorageLocale = (locale: string, region: string): void => {
  localStorage.setItem(LOCALE_KEY, JSON.stringify({ locale, region }));
};

export const setDocumentLanguage = (userLanguage: string, isoCode: string): void => {
  // Dont want to allow setting to empty values by mistake
  if (userLanguage.length === 2 && (isoCode.length >= 2 || isoCode.length <= 3)) {
    document.documentElement.lang = `${userLanguage}-${isoCode}`;
  }
};
