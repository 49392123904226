import React from 'react';
import Grid from '@material-ui/core/Grid';
import Logo from 'assets/images/caramalogo-footer.png';
import styled from 'styled-components';
// import { Colors } from '@smp/carama-ui-components';
import RegionSelectorLink from 'components/RegionSelector/RegionSelectorLink';
// const { GREEN } = Colors;
import { Hidden } from '@material-ui/core';
import { getLocalisedRoute } from 'constants/urls';

const FooterWrapper = styled(Grid)`
  background: rgb(236, 238, 238);
  padding: 50px;

  img {
    height: 57px;
  }

  @media only screen and (max-width: 768px) {
    padding: 1rem;
  }
`;

const RegionSelectorWrapper = styled(Grid)`
  color: #fff;

  > * {
    padding: 0px 20px;
  }

  h4 {
    margin: 0px;
  }

  span {
    text-align: left;
  }

  @media only screen and (max-width: 600px) {
    margin-top: 30px;
  }
`;

const FooterMainSection = styled(Grid)`
  && {
    h4 {
      font-size: 17px;
    }

    p {
      font-size: 16px;
      width: 100%;
      margin: 2px 0px;
    }

    a {
      color: rgb(31, 31, 31);
      font-size: 16px;
    }
  }
`;

const FooterLastRow = styled(Grid)`
  && {
    padding: 40px;
    background: rgb(236, 238, 238);
    border-top: 1px solid #cfcfcf;

    a {
      color: rgb(31, 31, 31);
      font-size: 16px;
    }

    p {
      margin: 0px;
      font-size: 16px;
    }

    @media only screen and (max-width: 600px) {
      > * {
        margin: 10px 0px;
      }
    }
  }
`;

type FooterProps = Record<string, any>;

const Footer: React.FC<FooterProps> = ({
  footerSupport,
  footercontact,
  footerLastRow,
  regSelectorText,
}: FooterProps) => {
  const appUrl = window.location.href;

  const supportLinks = [
    `${appUrl.replace(/\/$/, '')}${getLocalisedRoute('consumer')}${getLocalisedRoute('faq')}`,
    `${appUrl.replace(/\/$/, '')}${getLocalisedRoute('consumer')}${getLocalisedRoute('contactUs')}`,
    `${appUrl.replace(/\/$/, '')}${getLocalisedRoute('consumer')}${getLocalisedRoute('cookies')}`,
  ];

  return (
    <footer>
      <FooterWrapper container alignItems="flex-start" justify="space-between">
        <FooterMainSection container item xs>
          <Grid item xs={12}>
            <h4>{footerSupport['heading-4']}</h4>
          </Grid>
          {footerSupport['paragraph'].map((item, index) => (
            <p key={index}>
              <a href={supportLinks[index]}>{item}</a>
            </p>
          ))}
        </FooterMainSection>

        <FooterMainSection container item xs>
          <Grid item xs={12}>
            <h4>{footercontact['heading-4']}</h4>
          </Grid>
          {footercontact['paragraph'].map((item, index) =>
            index == 0 ? (
              <a href={'tel: ' + item} key={index}>
                <p>{item}</p>
              </a>
            ) : (
              <p key={index}>{item}</p>
            )
          )}
        </FooterMainSection>

        <Grid item xs>
          <RegionSelectorWrapper container>
            <Grid item>
              <RegionSelectorLink text={regSelectorText} />
            </Grid>
          </RegionSelectorWrapper>
        </Grid>
        <Hidden smDown>
          <Grid item xs>
            <Grid container alignItems="center" justify="flex-end">
              <img src={Logo} alt="CARAMA" title="CARAMA" />
            </Grid>
          </Grid>
        </Hidden>
      </FooterWrapper>
      <FooterLastRow container alignItems="center" justify="space-between">
        {footerLastRow.map(item => {
          if (item.url) {
            return (
              <Grid item xs={12} sm={'auto'}>
                <a href={item.url}>{item.text}</a>
              </Grid>
            );
          } else {
            return (
              <Grid item xs={12} sm={'auto'}>
                <p>{item.value}</p>
              </Grid>
            );
          }
        })}
      </FooterLastRow>
    </footer>
  );
};

export default Footer;
