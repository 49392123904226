import { createBrowserHistory } from 'history';
import { PUBLIC_URL } from 'constants/env';

const history = createBrowserHistory({ basename: PUBLIC_URL });

export const navigateToPage = page => () => history.push(page);

export const location = window.location.href;
export const origin = window.location.origin;

export const urlSoftPushState = (url: string): void => {
  window.history.pushState({}, null, url);
};

export default history;
